import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../templates/Layout";

export const pageQuery = graphql`
    query About {
        mdx(slug: {eq: "about-us"}) {
            body
            frontmatter {
              title
            }
        }
    }
`


class About extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <h1>{data.frontmatter.title}</h1>
                <MDXRenderer>
                    {data.body}
                </MDXRenderer>
            </Layout>
        )
    }
}
        
export default About